var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-1",attrs:{"fluid":""}},[_c('base-material-card',{staticClass:"px-5 py-4 ml-0",attrs:{"color":"primary","icon":"mdi-map-marker-path","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Extensões de Rede :: "+_vm._s(_vm.nomeEmpresa)+" ")])]},proxy:true}])},[_c('v-btn',{staticClass:"mt-5",staticStyle:{"float":"left"},attrs:{"id":"btn-novo-servidor","color":"success","dark":"","elevation":"1","relative":"","text":"","medium":""},on:{"click":function($event){return _vm.$router.push({ name: 'Extensão de Rede Adicionar' })}}},[_c('v-icon',{attrs:{"left":"","size":"30px"}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" Nova Extensão ")],1),_c('v-text-field',{staticClass:"ml-auto mt-4 break-search",staticStyle:{"max-width":"250px"},attrs:{"id":"extensao-rede-search","append-icon":"mdi-magnify","label":"Procurar","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-divider',{staticClass:"mt-10"}),_c('v-data-table',{staticClass:"servidores-table",attrs:{"headers":[
        {
          text: '#',
          value: 'id'
        },
        {
          text: 'Data Base',
          value: 'data_base',
          align: 'center'
        },
        {
          text: 'Alta Tensão',
          value: 'alta_tensao_km',
          align: 'center'
        },
        {
          text: 'Média Tensão',
          value: 'media_tensao_km',
          align: 'center'
        },
        {
          text: 'Baixa Tensão',
          value: 'baixa_tensao_km',
          align: 'center'
        },
        {
          text: 'Ramal',
          value: 'ramal_km',
          align: 'center'
        },
        {
          text: 'Total de Rede',
          value: 'total_rede',
          align: 'center'
        },
        {
          text: 'Status',
          value: 'situacao',
          align: 'center'
        },
        {
          sortable: false,
          text: 'Ações',
          value: 'actions',
          class: 'pl-4'
        }
      ],"items":_vm.items,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalExtensoes,"mobile-breakpoint":800,"loading":_vm.loading,"hide-default-header":_vm.loading,"hide-default-footer":_vm.loading,"no-data-text":"Nenhuma extensão de rede cadastrada"},on:{"update:search":function($event){_vm.search=$event},"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("addLeadingZero")(item.id))+" ")]}},{key:"item.data_base",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseDateToMonthYear")(item.data_base))+" ")]}},{key:"item.alta_tensao_km",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(item.alta_tensao_km))+" ")]}},{key:"item.media_tensao_km",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(item.media_tensao_km))+" ")]}},{key:"item.baixa_tensao_km",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(item.baixa_tensao_km))+" ")]}},{key:"item.ramal_km",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(item.ramal_km))+" ")]}},{key:"item.total_rede",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("parseNumberToFloatBR")(item.total_rede))+" ")]}},{key:"item.situacao",fn:function(ref){
      var item = ref.item;
return [_c('extensao-rede-status',{attrs:{"status":item.situacao}})]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{staticClass:"px-1 ml-n1",attrs:{"id":"action-edit","color":"gray","min-width":"0","fab":"","icon":"","x-small":"","disabled":!item.situacao},on:{"click":function($event){return _vm.$router.push({
              name: 'Extensão de Rede Editar',
              params: { id: item.id }
            })}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"id":"action-more","color":"white","height":"22px","width":"22px","fab":"","x-small":"","elevation":"1"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openDialogChangeStatus(item)}}},[_c('v-list-item-title',[(item.situacao)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-map-marker-remove-variant ")]):_c('v-icon',[_vm._v(" mdi-map-marker-check ")]),(item.situacao)?_c('span',[_vm._v(" Inativar ")]):_c('span',[_vm._v(" Ativar ")])],1)],1)],1)],1)]}},{key:"footer.page-text",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('rechargeTable', _vm.options)}}},'v-icon',attrs,false),on),[_vm._v("mdi-refresh")])]}}])},[_c('span',[_vm._v("Clique aqui para recarregar as extensões de rede")])])]},proxy:true}],null,true)})],1),_c('dialog-creation-log',{attrs:{"dialog":_vm.dialogCreationLog,"item":_vm.dialogCreationLogData},on:{"update:dialog":function($event){_vm.dialogCreationLog=$event}}}),_c('dialog-change-status',{attrs:{"dialog":_vm.dialogChangeStatus},on:{"update:dialog":function($event){_vm.dialogChangeStatus = $event},"submitTriggered":function($event){return _vm.changeStatus(_vm.dialogChangeStatusData.id)}}},[_c('template',{slot:"title"},[(_vm.dialogChangeStatusData.situacao)?_c('span',[_vm._v(" Inativar ")]):_c('span',[_vm._v(" Ativar ")]),_vm._v(" extensão #"+_vm._s(_vm._f("addLeadingZero")(_vm.dialogChangeStatusData.id))+" ")]),_c('template',{slot:"body"},[_vm._v(" Tem certeza que deseja "),(_vm.dialogChangeStatusData.situacao)?_c('span',[_vm._v(" inativar ")]):_c('span',[_vm._v(" ativar ")]),_vm._v(" essa extensão? ")])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }