<template>
  <v-container
    class="pa-1"
    fluid
  >
    <base-material-card
      color="primary"
      icon="mdi-map-marker-path"
      inline
      class="px-5 py-4 ml-0"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Extensões de Rede :: {{ nomeEmpresa }}
        </div>
      </template>

      <v-btn
        id="btn-novo-servidor"
        color="success"
        dark
        elevation="1"
        class="mt-5"
        style="float: left"
        relative
        text
        medium
        @click="$router.push({ name: 'Extensão de Rede Adicionar' })"
      >
        <v-icon
          left
          size="30px"
        >
          mdi-plus-circle
        </v-icon>
        Nova Extensão
      </v-btn>

      <v-text-field
        v-model="search"
        id="extensao-rede-search"
        append-icon="mdi-magnify"
        class="ml-auto mt-4 break-search"
        label="Procurar"
        hide-details
        single-line
        style="max-width: 250px"
      />

      <v-divider class="mt-10" />

      <v-data-table
        class="servidores-table"
        :headers="[
          {
            text: '#',
            value: 'id'
          },
          {
            text: 'Data Base',
            value: 'data_base',
            align: 'center'
          },
          {
            text: 'Alta Tensão',
            value: 'alta_tensao_km',
            align: 'center'
          },
          {
            text: 'Média Tensão',
            value: 'media_tensao_km',
            align: 'center'
          },
          {
            text: 'Baixa Tensão',
            value: 'baixa_tensao_km',
            align: 'center'
          },
          {
            text: 'Ramal',
            value: 'ramal_km',
            align: 'center'
          },
          {
            text: 'Total de Rede',
            value: 'total_rede',
            align: 'center'
          },
          {
            text: 'Status',
            value: 'situacao',
            align: 'center'
          },
          {
            sortable: false,
            text: 'Ações',
            value: 'actions',
            class: 'pl-4'
          }
        ]"
        :items="items"
        :search.sync="search"
        :options.sync="options"
        :server-items-length="totalExtensoes"
        :mobile-breakpoint="800"
        :loading="loading"
        :hide-default-header="loading"
        :hide-default-footer="loading"
        no-data-text="Nenhuma extensão de rede cadastrada"
      >
        <template v-slot:[`item.id`]="{ item }">
          {{ item.id | addLeadingZero }}
        </template>
        <template v-slot:[`item.data_base`]="{ item }">
          {{ item.data_base | parseDateToMonthYear }}
        </template>
        <template v-slot:[`item.alta_tensao_km`]="{ item }">
          {{ item.alta_tensao_km | parseNumberToFloatBR }}
        </template>
        <template v-slot:[`item.media_tensao_km`]="{ item }">
          {{ item.media_tensao_km | parseNumberToFloatBR }}
        </template>
        <template v-slot:[`item.baixa_tensao_km`]="{ item }">
          {{ item.baixa_tensao_km | parseNumberToFloatBR }}
        </template>
        <template v-slot:[`item.ramal_km`]="{ item }">
          {{ item.ramal_km | parseNumberToFloatBR }}
        </template>
        <template v-slot:[`item.total_rede`]="{ item }">
          {{ item.total_rede | parseNumberToFloatBR }}
        </template>
        <template v-slot:[`item.situacao`]="{ item }">
          <extensao-rede-status :status="item.situacao" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            id="action-edit"
            color="gray"
            min-width="0"
            class="px-1 ml-n1"
            fab
            icon
            x-small
            @click="
              $router.push({
                name: 'Extensão de Rede Editar',
                params: { id: item.id }
              })
            "
            :disabled="!item.situacao"
          >
            <v-icon small> mdi-pencil </v-icon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                id="action-more"
                color="white"
                class="ml-1"
                height="22px"
                width="22px"
                fab
                x-small
                elevation="1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small> mdi mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="openDialogChangeStatus(item)">
                <v-list-item-title>
                  <v-icon
                    small
                    v-if="item.situacao"
                  >
                    mdi-map-marker-remove-variant
                  </v-icon>
                  <v-icon v-else> mdi-map-marker-check </v-icon>
                  <span v-if="item.situacao"> Inativar </span>
                  <span v-else> Ativar </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
        <template v-slot:[`footer.page-text`]>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="$emit('rechargeTable', options)"
                >mdi-refresh</v-icon
              >
            </template>
            <span>Clique aqui para recarregar as extensões de rede</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </base-material-card>
    <dialog-creation-log
      :dialog.sync="dialogCreationLog"
      :item="dialogCreationLogData"
    />
    <dialog-change-status
      :dialog="dialogChangeStatus"
      @update:dialog="dialogChangeStatus = $event"
      @submitTriggered="changeStatus(dialogChangeStatusData.id)"
    >
      <template slot="title">
        <span v-if="dialogChangeStatusData.situacao"> Inativar </span>
        <span v-else> Ativar </span> extensão #{{
          dialogChangeStatusData.id | addLeadingZero
        }}
      </template>
      <template slot="body">
        Tem certeza que deseja
        <span v-if="dialogChangeStatusData.situacao"> inativar </span>
        <span v-else> ativar </span>
        essa extensão?
      </template>
    </dialog-change-status>
  </v-container>
</template>

<script>
import CompanyService from '@/services/CompanyService';
import dayjs from 'dayjs';
import ExtensaoRedeStatus from '@/components/general/UploadFiles/ExtensaoRedeStatus.vue';
import DialogChangeStatus from '@/components/general/DialogChangeStatus.vue';
import DialogCreationLog from '@/components/general/DialogCreationLog.vue';

export default {
  name: 'ServidoresBancosDadosListar',
  components: {
    DialogCreationLog,
    DialogChangeStatus,
    ExtensaoRedeStatus
  },

  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    search: '',
    dialogCreationLog: false,
    dialogCreationLogData: {},
    dialogChangeStatus: false,
    dialogChangeStatusData: {
      id: undefined,
      nome: undefined
    },
    options: {}
  }),

  methods: {
    openDialogChangeStatus(item) {
      this.dialogChangeStatusData = item;
      this.dialogChangeStatus = true;
    },

    changeStatus(id) {
      CompanyService.changeNetworkExtentStatus(id)
        .then(() => {
          this.$toast.success(
            'Status da extensão atualizado com sucesso.',
            '',
            { position: 'topRight' }
          );
          this.$emit('status:changed', this.options);
        })
        .catch((error) => {
          this.$toast.error('Erro ao atualizar o status da extensão.', '', {
            position: 'topRight'
          });
          console.error(error);
        });
      this.dialogChangeStatus = false;
    }
  },

  filters: {
    parseDateToMonthYear: function (str) {
      return str && dayjs(str).format('MM/YYYY');
    }
  },

  computed: {
    nomeEmpresa() {
      return this.data[0];
    },
    items() {
      return this.data[1];
    },
    totalExtensoes() {
      return this.data[2];
    }
  },

  watch: {
    options() {
      this.$emit('searchRequested', this.options, this.filters);
    }
  }
};
</script>
